export default {
	header_title: "Dashboard",
	user_type: "%(lable)s",
	Portfolio_title: "Crypto Portfolio",
	your_portfolio: "Crypto Balances",
	suissebase_title: "SuisseBase Crypto Wallets",
	suissebase_subtitle: "Fiat Balances",
	markets_title: "Markets",
	markets_subtitle: "Trade your crypto into any currency",
	deposit: "Receive",
	withdraw: "Send %(coin)s",
	buy: "Buy",
	sell: "Sell",
	load_more: "Load more ...",
	ftr_home: "Home",
	ftr_careers: "Careers",
	ftr_tc_p: "Legan & Policy",
	search_currency: "Search Currency",
	buy_assets: "Buy Crypto",
	sell_assets: "Sell Crypto",
	deposite_a_crypto: "Receive a Crypto",
	deposite_a_cryto_txt: "Get crypto by simply sharing your QR address",
	purchase_a_crypto: "Buy your Crypto for Cash",
	all: "Max",
	tabs_All: "All",
	gainers: "Gainers",
	losers: "Losers",
	db_slider_title: "Get $10 in free crypto",
	db_slider_desc:
		"Setup a recurring buy of $50 or more and get $10 after your both 4th and 8th buys",
	db_slider_btn: "Set up recurring buy",
	selected_crypto: "Buy %(coin)s",
	crypto_compare_val: "1 %(coin)s - $%(value)s ",
	find_with_wallet: "Select your wallet",
	refresh_newprice: "Please refresh to get new price",
	confirm_btn_text: "Confirm %(counter)s",
	exchange_rate: "Exchange Rate",
	effective_fee:"Effective Fee",
	amount: "Amount",
	suissebase_fee: "Suissebase Fee",
	estimated_total: "Estimated Total",
	summary_hint_text: "Your final amount might be changed with in 10 seconds.",
	pay: "Pay %(counter)s",
	cancel: "Cancel",
	link_a_card: "Link a Card or Receive",
	select_from_below: "Select from below",
	credit_card: "Credit Card",
	credit_card_text: "Use a credit or debit card",
	desosit_text: "Receive from an address or existing wallet",
	name_on_card: "Name on card",
	card_number: "Card number",
	expiry: "Expiry",
	cvv: "CVV",
	type_billing_address: "Type in your billing address",
	change_billing_address: "Change Billing Address",
	confirm_billing_address: "Confirm Billing Address",
	billing_address: "Billing Address",
	delivery_address: "Your delivery address",
	select_a_currency: "Select a currency",
	address_hint_text: "Be sure to select the right cryptocurrency.",
	address_hint_text_1:
		"If you send crypto to the wrong address (eg, Bitcoin to a Bitcoin Cash address), your crypto will be lost.",
	address_hint_text_2: "Be sure to select the right cryptocurrency.",
	copy: "Copy",
	share: "Share",
	from_crypto: "From Crypto",
	from_fiat: "From Fiat",
	wire_transfer_mthd: "Wire transfer method",
	recommended: "Recommended",
	select_a_method: "Select a method",
	sepa: "sepa",
	wire_transfer: "Wire transfer",
	swift: "swift",
	no_fee: "No fee",
	sell_btc: "sell %(coin)s",
	select_wallet1: "Please select wallet",
	confirm_sell: "Confirm selling your crypto",
	sell_compare_val: "1 %(coin)s - $%(value)s",
	preview: "Preview %(counter)s",
	success_msg: "Thank You",
	success_decr: "Your order has been placed successfully",
	confirm_msg: "Waiting for Confirmation",
	confirm_decr: "Your order has been placed Confirm",
	withdraw_a_crypto: "Withdraw a Crypto",
	withdraw_a_crypto_text: "Select an asset to send from your crypto wallet",
	usd_available: "USD 106.79 available",
	send_wallet_fiat: "Send from your wallet and into fiat",
	suissebase_personal: "Suissebase Personal",
	avail_wallet_weprovide: "Available wallet we provide",
	withdraw_Btc: "Withdraw BTC",
	withdraw_summary_sub: "Send your currency address.",
	verify_identity: "Verify your Identity",
	swap_title: "New Swap",
	transfor_title: "Transfer",
	swap_desc:
		"Instantly exchange your crypto into any currency we offer in your wallet.",
	
		transfor_desc:
		"Instantly exchange your crypto into any wallet	 we offer in your wallet.",
			swap_from: "Swap From",
	swap_to: "To Receive",
	wallet_in_usd: "Wallet in USD",
	purchase_fiat: "Purchase a Fiat",
	purchase_fiat_text:
		"Your wallet is empty, you don’t have any assets to make transactions. Follow this link and %(link)s  some cash.",
	link_newcard: "Link new card",
	pick: "pick",
	confirm_swap: "Confirm Swap %(counter)s",
	total: "Total",
	confirm_transaction: "Receive Fiat Summary",
	confirm_transaction_decs: "Wallet in USD",
	add_fund: "Add Funds",
	confirm_text: "Swapping 0.00549635 BTC for 0.10183318 ETH",
	deposite_Btc: "Receive BTC",
	sell_your_crypto_for_cash: "Sell your Crypto for Cash",
	buy_your_crypto_for_cash_text:
		"Easily buy crypto straight to your wallet.",
	sell_your_crypto_for_cash_text:
		"Easily sell crypto straight from your wallet.",
	sell_your_fiat_for_cash: "Sell your Fiat for Cash",
	// menu_buy_sell: "Buy / Sell",
	menu_buy_sell:"Trade",
	menu_transactions_history: "All Transactions",
	menu_swap: "Swap",
	//menu_send_receive: "Deposit / Withdraw",
	menu_send_receive:"Send/Receive",
	menu_mass_pay: "Mass Payments",
	menu_payments: "Bill Payments",
	menu_internal_transfer: "Internal Transfer",
	security: "Security",
	current_balance: "Current Balance",
	min: "Min",
	half: "Half",
	assets: "Assets",
	address: "Address",
	confirm_now: "Confirm Now %(counter)s",
	buy_sell_address:
		"UNIT 527 TOWER 4, SMDC Grace Residences, Cayetano Blvd. Brgy. Ususan, Taguig City 1630 PH",
	withdraw_to: "Withdraw to",
	btc_address: "BTC Binance Address",
	fund_with_card: "Fund with card",
	fund_with_card_text: "How much would you like to add?",
	preview_swap: "Preview Swap",
	deposit_link:
		"Your wallet is empty, you don’t have any assets to make transactions. Follow this link and %(link)s some cash.",
	link: "link",
	agree_to_suissebase:
		"I agree to Suissebase’s %(link)s and its return, refund and cancellation policy.",
	terms_service: "Terms of Service",
	return_to_buy: "Back to Buy",
	return_to_sell: "Back to Sell",
	fidor_bank: "Fidor Bank AG",
	transaction_submitted: "Transaction Submitted",
	swapped_btc: "Swapped 0.00549635 BTC for 0.10183318 ETH",
	bank_frick: "Bank Frick",
	scan_your_crypto: "Scan your BTC",
	center_qr: "Center the QR Scanner",
	close: "Close",
	note: "Note",
	card_supported: "Card type not supported",
	need_to_replenish:
		"Need to replenish your wallet? Follow this link and %(link)s some cash.",
	lets_verified: "Let's get you verified",
	confirm_your_identity:
		"Suissebase.ch would need to confirm your identity a process is powered by our partner.",
	prepare_valid: "Prepare a valid document",
	make_sure: "Make sure it's not expired of physically damaged",
	user_smartphone: "User a smartphone",
	you_need_smartphone: "You need a smartphone in order to continue",
	qption1: "Option 1: Scan the QR code",
	qption1_answer: "Scan the QR code with your camera app",
	qption2: "Option 2: Send link via SMS",
	qption2_answer: "We’ll text a secure link to your mobile at no extra cost.",
	return_to_fiat: "Return to Fiat",
	admin_fee: "Admin Fee",
	free: "Free",
	to: "To",
	from: "From",
	current_security_level: "CURRENT SECURITY LEVEL",
	current_security_text:
		"Your account has security features switched off, leaving it potentially vulnerable to specific attacks. Set up these security features to improve the security of your account.",
	medium: "Medium",
	change_pass_word: "Change Password",
	protect_your_account: "Protect your account",
	settings: "Settings",
	general: "General",
	privacy_policy: "Privacy Policy",
	logout: "Logout",
	themes: "Themes",
	notifications: "Notifications ",
	local_currency: "Local Currency",
	language: "Language",
	preferences: "Preferences",
	wallet_version: "Wallet Version",
	about: "About",
	USD_wallet: "USD Wallet",
	start: "Start",
	start_text:
		"We are a platform that connects banks, payment systems, and people.",
	personal: "Personal",
	wallets: "Wallets",
	wallets_text: "Full control of your private keys.",
	cards: "Cards",
	exchange: "Exchange",
	business: "Business",
	business_text:
		"User can create a separate account such as a corporate segregated wallet system.",
	crypto: "Crypto",
	corporate_wallet: "Corporate Wallet",
	connect: "Connect",
	meet_our_team: "Meet Our Team",
	report_a_bug: "Report A Bug",
	FAQ: "FAQ",
	contact_us: "Contact Us",
	sign_in: "Sign In",
	security_text:
		"Your account has security features switched off, leaving it potentially vulnerable to specific attacks. Set up these security features to improve the security of your account.",
	spend: "Spend",
	spend_text:
		"Your account has security features switched off, leaving it potentially vulnerable to specific attacks. Set up these security features to improve the security of your account.",
	chat: "Chat",
	current_security: "CURRENT SECURITY LEVEL",
	status: "to see your status",
	michael_quiapos: "Michael Quiapos",
	great: "Great. i will have a look...",
	address_book: "Address Book",
	case: "Cases",
	invite_friends: "Invite Friends",
	light_theme: "Light Theme",
	localization: "Localization",
	localization_text:
		"User can create a separate account such as a corporate segregated wallet system.",
	lang: "lang",
	support: "Support",
	help_center: "Help Center",
	social_networks: "Social Networks",
	medium_text: "CURRENT SECURITY LEVEL %(link)s to see your status",
	backup_wallet: "Backup Wallet",
	reset_wallet: "Reset Wallet",
	always_ask_pin: "Always ask pin",
	activate_face: "Activate face ID",
	activate_biometry: "Activate biometry",
	buy_and_sell: "Buy and Sell",
	swap_services: "Swap Services",
	deposit_and_withdraw: "Receive and Send",
	the_dashboard: "The Dashboard",
	buy_crypto: "Buy Crypto",
	Beneficiary_BankDetails: "Beneficiary Bank Details",
	bank_type: "Bank Type",
	Bank_account: "Bank Account Number",
	Bank_account_iban: "IBAN",
	Bank_account_iban_name: "Bank Account Number/IBAN",
	iban: "IBAN",
	BIC_SWIFT_routing_number: "BIC / SWIFT / Routing Code",
	BIC_SWIFT_ABArouting_number: "BIC/SWIFT/ABA Routing Code",
	bicnumber: "BIC",
	swiftcode: "Swift Code",
	Bank_name: "Bank Name",
	Bank_address1: "Bank Address",
	Bank_address2: "Bank Address Line 2",
	Bank_address3: "Bank Address Line 3",
	Beneficiary_Details: "Recipient's Details",
	Bank_Details: "Bank Details",
	Recipient_full_name: "Recipient Full Name",
	Recipient_address1: "Recipient Address",
	Recipient_address2: "Recipient Address Line 2",
	Recipient_address3: "Recipient Address Line 3",
	Reference: "Reference",
	//Fiat_deposit: "Fiat Deposit / Withdraw ",
	Fiat_deposit: "Receive/Send Fiat",
	Fiat_withdrawl: "Fiat Withdrawal",
	Beneficiary_Accountname: "Beneficiary Account Name",
	Innovative_Concepts: "INNOVATIVE CONCEPTS AG",
	Beneficiary_Accountaddress: "Beneficiary Account Address",
	PL_DU_BOURG_DE_FOUR: "PL DU BOURG DE FOUR6,1204 GENEVE,SWITZERLAND",
	Beneficiary_account: "Beneficiary Account",
	for_Domestic_wires: "For Domestic Wires",
	for_international_wires: "For SEPA Wires",
	Swift_BICcode: "Swift / BIC Code",
	SIGNU: "%(value)s",
	beneficiaryBank: "Beneficiary Bank Name",
	signature_bank: "%(value)s",
	beneficiary_Bank_address: "Beneficiary Bank Address",
	bank_Reference_No: "Bank Reference No",
	Fifth_Avenue: "%(value)s",
	reference: "Customer ID",
	reference_hint_text:
		"This code helps us in identifying your deposit. Please include the reference code when submitting the wire transfer to us.",
	desposite_text:
		"Ireto ambany ireto ny antsipirihan'ny kaonty any amin'ny banky ho an'ny vola manokana nosafidianao. Takiana aminao ny hihaino ny vola amin'ny antsipirihany avy amin'ny banky.",
	EUR_amount: "EUR Amount",
	Fee: "Fee",
	Amount_to_pay: "Amount to pay",
	place_an_order: "place an order",
	Routing_number: "Routing Number",
	Choose_a_unique_pass_word_to_protect_your_account:
		"Choose a unique password to protect your account.",
	Save_btn_text: "Save",
	forgot_your_pass_word: "Forgot your password?",
	Require_all_devices_to_signin: "Require all devices to sign in ",
	with_new_pass_word: "with new password",
	Yes: "Yes",
	summar_info: "Summary Information",
	current_pass_word: "Current Password",
	new_pass_word: "New Password",
	convert: "Convert",
	EUR_text:
		"Please choose the currency you would like to receive in,and the bank account details for the currency chosen will be displayed.The next step is for you to wire your funds to the bank details provided and remember to INCLUDE the reference number.",
	BICcode: "BIC",
	EPUALT: "EPUALT22XXX",
	account_holder_name: "Account Holder Name",
	innovative_concepts: "Innovative Concepts",
	wire_16_ZBPHXFHI: "wire_16_ZBPHXFHI",
	suisse_btn_refresh: "Click to get new rates",
	BankName: "Bank Name",
	proceed: "Proceed",
	description: "Description",
	with_draw: "Send",
	crypto_with_draw_success: "Back to Send Crypto",
	withdrawal_success: "Withdraw success",
	dep_with_assets: "Receive Crypto",
	send_crypto: "Send Crypto",
	links: "%(link)s",
	remarks: "Remarks",
	customer_remarks:"Customer Remarks",
	state: "State",
	zipcode: "Zip code",
	userName: "User Name",
	manage_account: "Personal Account",
	Deposit_success: "Receive Success",
	return_to_depositfiat: "Back to Receive Fiat",
	withdrawCrypto: "Send Crypto",
	withdrawFiat: "Send Fiat",
	cryptoAddress: "Add Crypto Address",
	cryptoAddressDetails:"Crypto Address Details",
	fiatAddress: "Select Fiat Address",
	withdraw_fiat_summary: "Send Fiat Summary",
	withdraw_live: "Send Face recognition",
	confirm_withdraw: "Send confirm",
	back: "Back",
	liveVarification: "Send Crypto",
	Withdraw_liveness: "Live verification",
	confirm: "Confirm %(counter)s",
	change_10Sec_amount: "Your amount might be changed within 10 sec",
	tabName_All: "All",
	searchCurrency: "Search Currency",
	selectWallet: "Select Wallet",
	final_Amount:
		"Output is estimated. Final amount will vary due to market conditions.",
	agree_sell: "I agree to SuisseBase’s",
	terms: "Terms of Service",
	refund_cancellation: " and its return, refund and cancellation policy.",
	purchase_min: "You have entered an amount below the minimum purchase. The minimum amount is",
	purchase_max: "The maximum purchase amount is",
	enter_wallet: "Please enter amount to buy",
	select_wallet: "Please select wallet to proceed",
	insufficientFunds: "Insufficient funds",
	agree_terms:
		"Please click the checkbox after reading and agreeing to the Terms of Service before proceeding",
	All: "All",
	BuyandSell: "Buy/Sell",
	DepositandFiat: "Receive Fiat",
	DepositandCrypto: "Receive Crypto",
	accountType: "Account Type",
	Wallet: "Wallet",
	RequestDate: "Request Date",
	walletAddress: "Wallet Address",
	coinName: "Coin Name",
	availableCoins: "Received Coins",
	Date: "Date",
	Type: "Type",
	FromWallet: "From Wallet",
	FromValue: "From Value",
	Status: "Status",
	Reason_For_Rejection:"Reason For Rejection",
	ToWallet: "To Wallet",
	ToValue: "To Value",
	BeforeValue: "Before Value",
	AfterValue: "After Value",
	TotalAmount: "Total Amount",
	AmountInUsd: "Amount In Usd",
	ReferenceId: "Customer ID",
	Select: "Select",
	space: " ",
	menu_swap1: "Swap ",
	Ethereum: "Ethereum",
	balance: "Balance -",
	available: "available",
	Available: "Available",
	ExchangeRate: "Exchange Rate",
	ProfileInfo: "Profile Info",
	BasicInfo: "Personal Details",
	SelectAddress: "Select Address",
	SelectCurrency: "Select Currency",
	account_details:
		"Please ensure that the above details are accurate and correct as the transaction is irreversible.",
	Cancel_select: "Transaction can't be cancelled",
	Confirm_fiat: "Preview Send Fiat",
	Confirm_crypto: "Preview Send Crypto",
	totalbalance: "Total balance",
	select_currency: "Select a currency in your wallet",
	BasicInfotag:
		"Basic Info, like your name and photo, that you use on Suissebase",
	FirstName: "First Name",
	MiddleName: "Middle Name",
	LastName: "Last Name",
	Birthday: "Birthday",
	ReferenceCode: "Customer ID",
	ContactInfo: "Contact Info",
	EmailAddress: "Email Address",
	PhoneNumber: "Phone Number",
	address_book_tag: "User customized address book",
	AddressLabel: "Address Label",
	Coin: "Coin",
	submit: "Submit",
	Enteraddresslabel: "Address Label",
	Enteraddress: "Enter Address",
	AddFiatAddress: "Add Fiat Address",
	Selectcoin: "Select Coin",
	documents: "Documents",
	tab_crypto: "Crypto",
	tab_fiat: "Fiat",
	total_balance: "Total balance",
	wallet_address: "Send to your whitelisted cryptocurrency wallet.",
	enter_address: "Select address",
	enter_wallet_address: "Please select wallet address",
	enter_amount: "Please enter amount",
	amount_less: "Entered amount should be less than available balance",
	amount_max: "Entered amount should be less than max withdraw amount of ",
	amount_min: "You have entered an amount below the minimum withdrawal. The minimum amount is ",
	tag: "Tag",
	button: "Share",
	select_address: "Select address book",
	searchAddress: "Search Address Label",
	oops: "OOPS",
	address_available: "No addresses available",
	withdrawSummary: "Send Crypto Summary",
	selectCurrencyinWallet: "Select a currency in your wallet",
	agree_termsofservice:
		"Please click the checkbox after reading and agreeing to the Terms of Service before proceeding",
	withdraw_crypto: "Send crypto",
	TwoFactorAuthentication: "Two Factor Authentication",
	TwoFactorAuthentication_tag: "Protect your account with 2-step verification",
	FA_tag: "2FA",
	PreviewBuy: "Preview Buy",
	PreviewSell: "Preview Sell",
	Enabled: "Enabled",
	Disabled: "Disabled",
	Password: "Password",
	Modifiedon: "Modified on",
	Click_here_to_change_pass_word: "change",
	Type_your_current_pass_word: "Type your current password",
	Type_your_new_pass_word: "Type your new password",
	Re_type_your_new_pass_word: "Re-type your new password",
	confirm_pass_word: "Confirm Password",
	User_customized_settings: "User customized settings",
	theme: "Theme",
	dark_theme: "Dark Theme",
	light_thme: "Light Theme",
	RequestedDate: "Requested Date",
	title: "Title",
	Back_to_Swap: "Back to Swap",
	AuditLogs: "Audit Logs",
	TimeSpan: "Time Span",
	Features: "Feature",
	Last_1_Day: "Last 1 Day",
	All_Features: "All Features",
	search: "Search",
	feacture: "Feacture",
	Feature_Path: "Feature Path",
	Action: "Action",
	Custom_Dates: "Custom Dates",
	Start_Date: "Start Date",
	End_Date: "End Date",
	Select_Date: "Select Date",
	ok: "Ok",
	funds_to_swap: "Insufficiant funds to swap",
	No_data: "No Data Found",
	confirm_button: "Confirm",
	new_pass_word_msg: "Please enter new password",
	Back_to_Withdrawfiat: "Back to Send Fiat",
	New_Address: "New Address",
	coin: "Coin",
	price: "Price",
	beneficiaryAccountName: "Beneficiary Account Name",
	last_24hrs: "Last 24hrs",
	home: "Home",
	careers: "Careers",
	legal_policy: "",
	select_bank: "Select Bank Name",
	sellCrypto: "Sell Crypto",
	pleaseSelectWallet: "Please select wallet",
	available_amount_less: "Entered amount should be less than available amount",
	available_balance_less: "Entered amount should be less than balance",
	enter_minvalue: "You have entered an amount below the minimum sale. The minimum amount is ",
	enter_maxvalue: "You have to sell maximum of ",
	enter_coinvalue: "Please enter from coin Value",
	receive_coin: "Please select receive coin to swap",
	insufficient_balance: "Insufficient balance",
	select_coin: "Please select from coin",
	coin_swap: "Please select coin to swap",
	is_required: "Is required",
	current_pass_word_msg: "Please enter current password",
	confirm_pass_word_msg: "Please enter confirm password",
	one_record: "Please select one record",
	accept_terms:
		"Please click the checkbox after reading and agreeing to the Terms of Service before proceeding",
	amount_greater_zero: "Amount must be greater than zero.",
	exceeded_amount: "You have exceeded maximum amount",
	mkt_Cap: "Mkt Cap",
	total_volume: "Total Volume",
	total_supply: "Total Supply",
	full_screen: "Full screen",
	reload: "Reload",
	cockpit: "View All",
	settings_msg: "Settings saved successfully",
	notification_msg: "No notifications available",
	bank_msg: "Bank details not available",
	address_msg: "Address saved successfully",
	add: "Add",
	edit: "Edit",
	active_inactive: "Active/Inactive",
	location: "Location",
	ipAddress: "IP Address",
	menu_wallets: "Balances",
	crypto_wallets: "Crypto Balances",
	verification_code: "Enter verification code",
	withdraw_verification_options:
		"Please select withdraw verification options from below.",
	get_code: "Click here to get code",
	resend_code: "Click here to resend code",
	digit_code: " Enter 6 digit code sent to",
	selectcurrency: "Select Currency",
	bank_account_number: "Bank Account Number",
	invalid_code: "Invalid code",
	resend_text: "Click on Resend Button after 2min",
	please_enter_valid_content: "Please enter valid content",
	W: "W",
	M: "M",
	Y: "Y",
	copied: "Copied",
	request_fail: "Max attempts reached, Please try after some time",
	received: "Received",
	comssion: "Commission",
	WithdrawalFee: "Send Fee",
	thousandKText: "The maximum amount is $350,000",
	sucessText1: "Your order has been placed successfully,",
	sucessText2: "has been added into your wallet.",
	sucessText3: "amount has been debited from your wallet.",
	value_should_greater: " From coin value must be greater than zero",
	asset_directory: "Asset Directory",
	you_are_sending: "You are sending",
	fees: "Fees",
	you_receive: "Recipient will receive",
	company_name: "Business Name",
	withdraw_verification: "Send Verification",
	Phone_verification: "Phone Verification",
	Email_verification: "Email Verification",

	live_verification: "Live Verification",
		get_email: "Click here to get code",
	resend_email: "Click here to resend code",
	sent_verification: "Verification code sent",
	verify_btn: "Click here to verify",
	verify_button: "Click here to verify",
	addresslabel: "Address Label",
	currency:"Currency",
	noaddress_msg: "Address Book details not available.",
	address_type: "Address Type",
	addressState: "Address State",
	add_new_beneficiary: "Add New Beneficiary",
	kyc_documents: "KYC Documents",
	confirm_beneficiary: "Confirm Beneficiary",
	return_to_beneficairy: "Back to Add New Beneficiary",
	upload_documents: "Upload Documents",
	compliance: "(Compliance)",
	business_recipient: "Business Name/Recipient Full Name",
	get_your_own_iban: "Get Your Own IBAN",
	phone_invalid_code: "Invalid phone verification code",
	twofa_invalid_code: "Invalid authenticator code",
	email_invalid_code: "Invalid email verification code",
	sendTo: "Send",
	account_address: "Beneficiary Address",
	receiving_option:"Receiving Option",
	account_name: "Beneficiary Name",
	delete: "Delete",
	personal_account: 'Personal Account',
	business_account: "Business Account",
	empty: " ",
	Recipient_Business_name: "Recipient Full Name/Business Name",
	agree_to_signup: "By clicking Sign Up, I here by acknowledge that I agree to Suissebase's %(link)s Agreement and I've read the Privacy Policy",
	terms_Use: "Terms of Use",
	whitelist: "Declaration Form",
	favorite_name: "Save payee name as",
	Fait_Name: "Name",
	buisiness_name: "Beneficiary Name",
	email: "Email",
	Phone_No: "Phone Number",
	Address_Line1: "Address Line1",
	Address_Line2: "Address Line2",
	Address_Line3: "Address Line3",
	city: "City",
	State: "State",
	Country: "Country",
	Post_code: "Postal Code",
	menu_tranfor: "Transfer",
	tranfor_fromwlt:'From wallet',
	tranfor_towlt:'To wallet',
	tranfor_currencywlt:'Currency',
	tranfor_Amount:'Amount',
	transfor_btn_cnftransfor: 'Confirm Transfer',
	return_to_transfor: "Back to Transfer",
	referr: "My Referral",
	notification: "Notification Settings",
	transforsucessText1: "Your transaction was success,",
	Please_select: "Please select",
	at_least_2: "at least 2",
	verification_options: "of the Send verification options below.",
	Case_Number: "Case Number",
	Case_Title: "Case Title",
	Case_State: "Case State",
	Go_to_Partner: "Go to Partner",
	Referral_code: "Referral code",
	Personal_referral_link: "Personal referral link",
	Business_referral_link: "Business referral link",
	account_text: "Account Reference Confirmation/Letter",
	addressbook_note: "Note: Whitelisting of Crypto Address and Bank Account is required, Please add below.",
	bank_label: "Bank Label",
	first_party: "1st Party",
	third_party: "3rd Party",
	declaration: "Declaration form will be sent to",
	whitelist_note: "Please sign using link received in email to whitelist your address",
	select_country: "Select Country",
	select_state: "Select State",
	select_type: "Select Type",
	swift_code: "Swift Code",
	confirm_activate: "Confirm Activate",
	confirm_deactivate: "Confirm Deactivate",
	really_want: "Do you really want to",
	deactivate: "deactivate",
	activate: "active",
	No: "No",
	Myself_By_OwnBusiness: "Myself By OwnBusiness",
	SomeOne_Else: "SomeOne Else",
	Business: "Business",
	transfer_type: "Transfer Type",
	bankAddressDetails: "Add Bank Details",
	first_name: "First Name",
	last_name: "Last Name",
	addressline1:"Address Line 1",
	addressline2:"Address Line 2",
	addressline3:"Address Line 3",
	swifbictcode: "Swift / BIC Code",
	accountnumber:"Account Number",
	bankaddressline1:"Bank Address 1",
	bankaddressline2:"Bank Address 2",
	reasiontotransfor:'Reason For Transfer',
	relationtobenificiary:'Relationship To Beneficiary',
	saveWhitelistnameas:'Save Whitelist Name As',
	continue:'Continue',
	bankAddress: "Add Bank Address",
	send_fund: "Send",
	receive_funds: "Receive",
	createnow: "Create Now",
	transfer_funds: "Send",
	receve_funds: "Receive",
	suisse_wallets: "SuisseBase Wallet",
	send_crypto_address: "Send Crypto",
	buy_select_currency: "select currency to buy with",
	network: "Network",
	send_fiat_text: "Send from your SuisseBase FIAT Wallet",
    receive_fiat_text: "Receive in your SuisseBase FIAT Wallet",
	contact_amount_text: "Please contact support for higher amounts.",
	validate: "Validate",
	sell_select_currency: "select currency to receive",
	transactions_history: "Transaction History",
	transaction:"Transaction",
	batch_payments: "Batch Payment Summary",
	bathch_payments_preview: "Please confirm the field before acknowledging.",
	tab_BatchPayment: "Batch Payment",
	suissebase_title_crypto:"SuisseBase Crypto Wallet",
	Whitelisting_Status:"Whitelisting Status",
	fait_walets:"SuisseBase Fiat Wallets",
	sb_card_title:"Your Card",
	Dashboard:"Dashboard",
	notices:"Notices",
	personal_iban_transafer:"Personal IBAN Transfer",
	fiat_transfer_success:"Back to Transfer",
	suissebase_credit:"SuisseBase Credits",
	suissebasecredits:"SuisseBase Credits Available",
	tab_InternalCustomerTransfer:"Internal Customer Transfer",
	customer_transfer_success:"Back to Internal Customer Transfer",
	
};